import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { useSelector as useReduxSelector, useDispatch as useReduxDispatch, TypedUseSelectorHook } from "react-redux"
import { connectionCheckSlice, ReduxStateWithConnectionCheck } from "@/shared/lib/redux/slices/connection-check"
import { notificationsSlice, ReduxStateWithNotification } from "@/shared/lib/redux/slices/notifications"

export type ReduxStore = typeof reduxStore
export type ReduxState = ReturnType<typeof reduxStore.getState>
export type ReduxDispatch = typeof reduxStore.dispatch
export type ReduxThunkAction<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, Action>

const reducer = {
  notifications: notificationsSlice.reducer,
  connectionCheck: connectionCheckSlice.reducer,
}

type ReduxStateWithSlices = ReduxStateWithConnectionCheck & ReduxStateWithNotification

export function setupStore() {
  return configureStore<ReduxStateWithSlices>({
    reducer,
  })
}

export const reduxStore = setupStore()

export const useDispatch = useReduxDispatch<ReduxDispatch>
export const useSelector: TypedUseSelectorHook<ReduxState> = useReduxSelector
