import { useAuth } from "react-oidc-context"
import { ReactNode, useEffect } from "react"
import { log } from "@/shared/lib/logging/logger"
import { UserManagerEvents } from "oidc-client-ts"

// a union type of strings containing only those methods of UserManagerEvents that start with "add"
type AddEventListenerMethodName = Extract<keyof UserManagerEvents, `add${string}`>

function useLogAuthEvent(addEventMethodName: AddEventListenerMethodName) {
  const auth = useAuth()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return auth.events[addEventMethodName]((val: any) => {
      log(`Auth: ${addEventMethodName.slice(3)}`, val)
    })
  }, [auth.events, addEventMethodName])
}

/**
 * A component that adds logging for auth-related events.
 * @constructor
 */
export function AuthLogger(): ReactNode {
  useLogAuthEvent("addAccessTokenExpired")
  useLogAuthEvent("addUserSignedIn")
  useLogAuthEvent("addUserSignedOut")
  useLogAuthEvent("addUserSessionChanged")
  useLogAuthEvent("addUserUnloaded")
  useLogAuthEvent("addSilentRenewError")

  // maybe not log the user as this will print the access-token in the browsers console.
  // useLogAuthEvent("addUserLoaded")

  return null
}
