import { Action, createSlice, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit"
import { addNotification, NotificationItem, ReduxStateWithNotification, removeNotification } from "../notifications"
import { v4 } from "uuid"
import { fetchApi } from "@/shared/lib/dataloading/fetch-util"
import { createError } from "ems-device-manager/src/lib/redux/slices/notifications/utils"

const CONNECTION_CHECK_ROUTE = "/system/ping"

const CONNECTION_INTERVAL = 30000

type Timeout = ReturnType<typeof setTimeout>

export type ConnectionCheckState = {
  requestState: "initial" | "pending" | "failed" | "finished"
  statusCode?: number
  notificationId?: string
  connectionCheckIntervalId?: Timeout
}

// This slice should be re-usable. However, due to typing limitations of Redux(-Toolkit),
// we need to define the Type of the overall state in the app where this slice is mounted.
// This is not optimal because here in the slice, we don't "know" where the slice will be mounted.
// Still, it's better than copy+paste the whole slice.
// For this reason, we're here defining where this slice has to be mounted in the actual application
export type ReduxStateWithConnectionCheck = {
  connectionCheck: ConnectionCheckState
}
type ReduxGetState = () => ReduxStateWithConnectionCheck

const initialState: ConnectionCheckState = {
  requestState: "initial",
  statusCode: 200,
}

export const connectionCheckSlice = createSlice({
  name: "connectionCheck",
  initialState,
  reducers: {
    startRequest: (state) => {
      state.requestState = "pending"
    },
    success: (state, action: PayloadAction<{ statusCode: number }>) => {
      state.requestState = "finished"
      state.statusCode = action.payload.statusCode
      delete state.notificationId
    },
    failed: (state, action: PayloadAction<{ statusCode: number; notificationId: string }>) => {
      state.requestState = "failed"
      state.statusCode = action.payload.statusCode
      state.notificationId = action.payload.notificationId
    },
    startConnectionCheck: (state, action: PayloadAction<{ intervalId: Timeout }>) => {
      state.connectionCheckIntervalId = action.payload.intervalId
    },
    stopConnectionCheck: (state) => {
      delete state.connectionCheckIntervalId
    },
  },
})

function createNotificationItem(result: Response): NotificationItem {
  let messageKey: string
  if (result.status === 500) {
    messageKey = "connectionErrorInternal"
  } else {
    messageKey = "connectionError"
  }
  return createError(v4(), messageKey, "global_error")
}

function clearNotification(
  dispatch: ThunkDispatch<ReduxStateWithConnectionCheck, void, Action>,
  getState: ReduxGetState,
) {
  const notificationId = getState().connectionCheck.notificationId
  if (notificationId) {
    dispatch(removeNotification({ itemOrId: notificationId }))
  }
}

export function startRequest() {
  return async (
    dispatch: ThunkDispatch<ReduxStateWithConnectionCheck & ReduxStateWithNotification, void, Action>,
    getState: ReduxGetState,
  ) => {
    dispatch(connectionCheckSlice.actions.startRequest())
    let result
    try {
      result = await fetchApi(
        CONNECTION_CHECK_ROUTE,
        {
          method: "GET",
        },
        true,
      )
    } catch (error) {
      const notificationId = getState().connectionCheck.notificationId
      const lastStatusCode = getState().connectionCheck.statusCode

      // for a failed fetch we set an internal status code of -1
      if (lastStatusCode === -1) {
        // this case only happens when there was a error last time so we know for sure that notificationId was set
        dispatch(connectionCheckSlice.actions.failed({ statusCode: -1, notificationId: notificationId! }))
      } else {
        clearNotification(dispatch, getState)
        const newNotificationItem = createError(v4(), "connectionError", "global_error")
        dispatch(connectionCheckSlice.actions.failed({ statusCode: -1, notificationId: newNotificationItem.id }))
        dispatch(addNotification({ item: newNotificationItem }))
      }
    }

    if (result) {
      if (result.ok) {
        clearNotification(dispatch, getState)
        dispatch(connectionCheckSlice.actions.success({ statusCode: result.status }))
      } else {
        const notificationId = getState().connectionCheck.notificationId
        const lastStatusCode = getState().connectionCheck.statusCode

        if (lastStatusCode === result.status) {
          // this case only happens when there was a error last time so we know for sure that notificationId was set
          dispatch(connectionCheckSlice.actions.failed({ statusCode: result.status, notificationId: notificationId! }))
        } else {
          clearNotification(dispatch, getState)
          const newNotificationItem = createNotificationItem(result)
          dispatch(
            connectionCheckSlice.actions.failed({ statusCode: result.status, notificationId: newNotificationItem.id }),
          )
          dispatch(addNotification({ item: newNotificationItem }))
        }
      }
    }
  }
}

export function startConnectionCheck() {
  return (
    dispatch: ThunkDispatch<ReduxStateWithConnectionCheck & ReduxStateWithNotification, void, Action>,
    getState: ReduxGetState,
  ) => {
    if (!getState().connectionCheck.connectionCheckIntervalId) {
      dispatch(startRequest())
      const connectionCheckIntervalId = setInterval(() => {
        dispatch(startRequest())
      }, CONNECTION_INTERVAL)
      dispatch(connectionCheckSlice.actions.startConnectionCheck({ intervalId: connectionCheckIntervalId }))
    }
  }
}

export function stopConnectionCheck() {
  return (dispatch: ThunkDispatch<ReduxStateWithConnectionCheck, void, Action>, getState: ReduxGetState) => {
    const connectionCheckIntervalId = getState().connectionCheck.connectionCheckIntervalId
    if (connectionCheckIntervalId) {
      clearInterval(connectionCheckIntervalId)
      dispatch(connectionCheckSlice.actions.stopConnectionCheck())
    }
  }
}
