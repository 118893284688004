"use client"

import { reduxStore } from "./store"

import { Provider } from "react-redux"
import { PropsWithChildren, useEffect } from "react"
import { startConnectionCheck } from "@/lib/redux/slices"
import { getEnv } from "@/lib/get-env"

export function ReduxProvider({ children }: PropsWithChildren) {
  useEffect(() => {
    if (getEnv("EMS_FRONTEND_DISABLE_CONNECTION_CHECK") !== "true") {
      reduxStore.dispatch(startConnectionCheck())
    }
  })
  return <Provider store={reduxStore}>{children}</Provider>
}
