import { getEnvShared } from "@/shared/lib/get-env"

declare global {
  interface Window {
    envVariables: {
      EMS_FRONTEND_OAUTH_AUTHORITY: string
      EMS_FRONTEND_OAUTH_CLIENT_ID: string
      EMS_FRONTEND_OAUTH_SCOPE: string
      EMS_FRONTEND_BASE_URI: string

      EMS_FRONTEND_REST_ENDPOINT: string
      EMS_FRONTEND_DISABLE_CONNECTION_CHECK: string

      EMS_FRONTEND_APP_CARD_DEVICE_MANAGEMENT_LINK: string
      EMS_FRONTEND_APP_CARD_APP_MANAGEMENT_LINK: string
      EMS_FRONTEND_APP_CARD_MONITORING_LINK: string
      EMS_FRONTEND_APP_CARD_USER_MANAGEMENT_LINK: string

      EMS_FRONTEND_USER_MANUAL_SOURCE: string
      EMS_FRONTEND_ACCOUNT_SETTINGS_SOURCE: string
    }
  }
}

/**
 * This function is used to get environment variables in the browser context.
 * In the SSR context, this function returns an empty string.
 *
 * See '/public/env-variables.js' and '/docker/entrypoint.sh'
 *
 * @param key the key for the env variable
 */
export function getEnv(key: keyof Window["envVariables"]): string {
  return getEnvShared(key)
}
