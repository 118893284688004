import { getEnvShared } from "@/shared/lib/get-env"

const LOGGING_ENABLED = getEnvShared("EMS_FRONTEND_LOGGING_ENABLED") === "true"

export function isLoggingEnabled() {
  return LOGGING_ENABLED
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(message: string, ...optionalParams: any[]) {
  if (LOGGING_ENABLED) {
    if (optionalParams.length > 0) {
      console.log(message, optionalParams)
    } else {
      console.log(message)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function warn(message: string, ...optionalParams: any[]) {
  if (LOGGING_ENABLED) {
    if (optionalParams.length > 0) {
      console.warn(message, optionalParams)
    } else {
      console.warn(message)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function error(message: string, ...optionalParams: any[]) {
  if (LOGGING_ENABLED) {
    if (optionalParams.length > 0) {
      console.warn(message, optionalParams)
    } else {
      console.warn(message)
    }
  }
}
