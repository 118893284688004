"use client"
import { PropsWithChildren } from "react"
import { AuthProvider as OidcProvider, AuthProviderProps } from "react-oidc-context"
import { isLoggingEnabled, log } from "@/shared/lib/logging/logger"
import { AuthLogger } from "@/shared/lib/auth/auth-logger"

export type AuthProps = {
  oidcConfig: AuthProviderProps
}

export function AuthProvider({ children, oidcConfig }: PropsWithChildren<AuthProps>) {
  log("AuthProvider. Using oidcConfig:", oidcConfig)
  return (
    <OidcProvider {...oidcConfig}>
      {isLoggingEnabled() && <AuthLogger />}
      {children}
    </OidcProvider>
  )
}
