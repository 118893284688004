import { ReduxState } from "@/lib/redux"
import { _selectAllNotifications } from "@/shared/lib/redux/slices/notifications/selectors"
import { NotificationItem } from "@/shared/lib/redux/slices/notifications"

export const selectAllNotifications = (state: ReduxState) => _selectAllNotifications(state.notifications)

export const selectIsGlobalError = (state: ReduxState): boolean => {
  const notifications: NotificationItem[] = selectAllNotifications(state)
  return notifications.some(
    (notification: NotificationItem): boolean => notification.notificationType === "global_error",
  )
}
