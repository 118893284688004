import { Action, createSlice, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit"

export type IsoDateString = string
export type NotificationType = "error" | "global_error" | "success"

export type NotificationItem = {
  id: string
  notificationType: NotificationType
  messageKey: string
  timestampAdded: IsoDateString
  timeout?: number
  translate?: boolean
}

export type NotificationsState = {
  notifications: Array<NotificationItem>
}

export type ReduxStateWithNotification = {
  notifications: NotificationsState
}

const initialState: NotificationsState = {
  notifications: [],
}

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<{ item: NotificationItem }>) => {
      const newNotification: NotificationItem = action.payload.item
      if (!state.notifications.find((item) => item.id === newNotification.id)) {
        state.notifications.push(newNotification)
      }
    },
    removeNotification: (state, action: PayloadAction<{ itemOrId: NotificationItem | string }>) => {
      const itemOrId: string | NotificationItem = action.payload.itemOrId

      const id: string = typeof itemOrId === "string" ? itemOrId : (itemOrId as NotificationItem).id

      state.notifications = state.notifications.filter((item) => item.id !== id)
    },
    clearNotifications: (state) => {
      state.notifications = []
    },
  },
})

export function addNotification(action: { item: NotificationItem }) {
  return (dispatch: ThunkDispatch<ReduxStateWithNotification, void, Action>) => {
    const item = action.item

    dispatch(notificationsSlice.actions.addNotification({ item }))

    if (item.timeout) {
      setTimeout(() => {
        dispatch(notificationsSlice.actions.removeNotification({ itemOrId: item }))
      }, item.timeout)
    }
  }
}

export const { removeNotification, clearNotifications } = notificationsSlice.actions
