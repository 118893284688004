import { PropsWithChildren } from "react"
import { AbstractIntlMessages, NextIntlClientProvider } from "next-intl"

export enum Locale {
  en = "en",
}

type Props = {
  messages: AbstractIntlMessages
  locale?: Locale
}

export function InternalisationProvider({ children, locale = Locale.en, messages }: PropsWithChildren<Props>) {
  return (
    <NextIntlClientProvider timeZone={"Europe/Berlin"} locale={locale} messages={messages}>
      {children}
    </NextIntlClientProvider>
  )
}
