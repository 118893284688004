interface WindowWithEnv {
  envVariables: {
    [key: string]: string
  }
}

/**
 * This function is used to get environment variables in the browser context.
 * In the SSR context, this function returns an empty string.
 *
 * See '/public/env-variables.js' and '/docker/entrypoint.sh'
 *
 * @param key the key for the env variable
 */
export function getEnvShared<T extends string>(key: T): string {
  if (typeof window !== "undefined") {
    if ((window as unknown as WindowWithEnv).envVariables) {
      return (window as unknown as WindowWithEnv).envVariables[key]
    }
  }
  return ""
}
